import { FittingPosition, Vehicle, VehicleType } from "@tm/models"
import { FiltersSensorsRequest, FiltersSensorsResponse } from "../../../data/repositories/articles-loadSensorFilters/model"
import { SensorItemsRequest } from "../../../data/repositories/articles-loadSensorItems/model"
import { getBundleParams } from "../../../utils"

export function createSensorArticleFiltersRequest(vehicle: Vehicle | undefined): FiltersSensorsRequest | undefined {

    if (!vehicle) {
        return undefined
    }

    return {
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType || VehicleType.PassengerCar,
    }
}

export function createSensorArticlesRequest(vehicle: Vehicle | undefined, response: FiltersSensorsResponse | undefined): SensorItemsRequest | undefined {

    if (!vehicle || !response) {
        return undefined
    }

    const supplierIds = response.manufacturer.map(x => x.id || 0)

    if (!supplierIds.length || !response.productGroupId) {
        return undefined
    }

    return {
        productGroupIds: [response.productGroupId],
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType || VehicleType.PassengerCar,
        supplierIds,
        extendedAssortment: false,
        fittingSideFilter: FittingPosition.None,
        pageIndex: 1,
        pageSize: getBundleParams().tyresPageSize,
    }
}